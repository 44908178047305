<template>
    <div class="info-card-panner wallet-panner">
        <header class="panner-hd">
            <el-button type="text" class="pull-right more" @click="goto()">
                查看更多
                <i class="jk-icon-right"></i>
            </el-button>
            <h2 class="tit">
                <span class="line">我的</span>钱包
            </h2>
        </header>
        <div class="panner-bd wallet-panner-bd">
            <ul class="wallet-wraper">
                <li class="wallet-item wallet-item1">
                    <div class="num-box">
                        <span class="min-text red">￥</span>
                        <span class="int-num red">{{wallet.total | changePrice}}</span>
                        <span class="min-text red">.{{wallet.total | splicePrice}}</span>
                    </div>
                    <!-- <div class="item-type">元(人民币)</div> -->
                    <div class="status">总资金</div>
                    <div class="right-ling"></div>
                </li>
                <li class="wallet-item wallet-item2">
                    <div class="num-box">
                        <span class="min-text">￥</span>
                        <span class="int-num">{{data.surplus | changePrice}}</span>
                        <span class="min-text">.{{data.surplus | splicePrice}}</span>
                    </div>
                    <!-- <div class="item-type">元(人民币)</div> -->
                    <div class="status">账户余额(可提现)</div>
                    <div>
                        <!-- <el-link type="primary" @click="goto('1','0',1,'')">充值</el-link> -->
                        <!-- <el-link type="primary" @click="goto('1','0','',1)">提现</el-link> -->
                    </div>
                    <div class="right-ling"></div>
                </li>
                <li class="wallet-item wallet-item3" v-if="data.user_type==1">
                    <div class="num-box">
                        <span class="min-text">￥</span>
                        <span class="int-num">{{wallet.bond | changePrice}}</span>
                        <span class="min-text">.{{wallet.bond | splicePrice}}</span>
                    </div>
                    <!-- <div class="item-type">元(人民币)</div> -->
                    <div class="status">已托管保证金</div>
                    <div>
                        <!-- <el-link type="primary" @click="goto()">明细</el-link> -->
                    </div>
                    <div class="right-ling"></div>
                </li>
                <li class="wallet-item wallet-item3" v-else>
                    <div class="num-box">
                        <span class="min-text">￥</span>
                        <span class="int-num">{{wallet.remuneration | changePrice}}</span>
                        <span class="min-text">.{{wallet.remuneration | splicePrice}}</span>
                    </div>
                    <!-- <div class="item-type">元(人民币)</div> -->
                    <div class="status">已托管酬金</div>
                    <div>
                        <!-- <el-link type="primary" @click="goto()">明细</el-link> -->
                    </div>
                    <div class="right-ling"></div>
                </li>
                <li class="wallet-item wallet-item3">
                    <div class="num-box">
                        <span class="int-num">{{wallet.points_surplus | changePrice}}</span>
                    </div>
                    <div class="item-type">点券</div>
                    <div class="status">点券余额</div>
                    <div>
                        <!-- <el-link type="primary" @click="goto()">明细</el-link> -->
                        <!-- <el-link type="primary" @click="goto()">购买</el-link> -->
                    </div>
                </li>
            </ul>
        </div>
        <!-- <footer class="panner-ft">
            <div class="wallet-tip-text">
                <span v-if="data.user_type==1">
                    项目报名次数：
                    <i class="yellow num">{{data.apply_numbers||0}}次</i>
                </span>
            </div>
        </footer> -->
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "UcUserWallet",
    props: {
        data: {
            type: Object,
            // 对象或数组默认值必须从一个工厂函数获取
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            wallet: {}
        };
    },
    computed: {
        ...mapState(["userInfo"]),
    },
    methods: {
        goto() {
            this.$router.push({
                name: "userWallet"
            });
        }
    },
    created() {
        this.wallet = this.userInfo.info;
    }
};
</script>
<style lang="less" scoped>
@import "../../styles/ucUserWallet.less";
</style>





