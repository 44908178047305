<template>
    <div class="info-card-panner task-panner">
        <header class="panner-hd">
            <el-button type="text" class="pull-right more"
            @click="go_to('/user/task/list?status=0')"
            >
                查看更多
                <i class="jk-icon-right"></i>
            </el-button>
            <h2 class="tit">
                <span class="line">管理</span>我的项目
            </h2>
        </header>
        <div class="panner-bd task-panner-bd">
            <el-table
                :data="data"
                style="width: 100%"
                :row-class-name="tableRowClassName"
                :cell-style="cellStyle"
                header-row-class-name="tb-hd-style"
            >
                <el-table-column type="index" label="序号" width="80"></el-table-column>
                <el-table-column prop="task_name" label="项目名称" width="280"></el-table-column>
                <el-table-column prop="task_type" label="项目类型">
                    <template slot-scope="scope">{{get_task_type(scope.row.task_type)}}</template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">{{get_status(scope.row.status)}}</template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">打开工作台</el-button>
                        <el-button type="text" size="small">查看日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "UcUserTask",
    props: {
        data: {
            type: Array,
            // 对象或数组默认值必须从一个工厂函数获取
            default: function() {
                return [];
            }
        }
    },
    data() {
        return {
        };
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            // 为tab隔行设置样式
            if (rowIndex % 2 == 0) {
                // 单行 索引从0开始
                return "light-blue";
            } else {
                return "lignt-dark";
            }
            return "";
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            // 设置第三列的颜色
            if (columnIndex == 3) {
                return {
                    color: "#39BEA3"
                };
            }
        },
        get_task_type(type) {
            let str = "";
            switch (type) {
                case 1:
                    str = "竞价项目";
                    break;
                case 2:
                    str = "必选项目";
                    break;
                case 3:
                    str = "日薪项目";
                    break;
            }
            return str;
        },
        go_to(url) {
            this.$router.push(url);
        },
        get_status(status){
            let str = "";
            switch (status) {
                case 1:
                    str = "报名中";
                    break;
                case 2:
                    str = "评选中";
                    break;
                case 3:
                    str = "执行中";
                    break;
                    case 4:
                    str = "已完成";
                    break;
                case 5:
                    str = "调解中";
                    break;
                case 6:
                    str = "已结束";
                    break;
            }
            return str;
        },
        handleClick(row) {
            //查看详情页面跳转
            this.$router.push("task/list/all?task_id="+row.id);
        },
    }
};
</script>
<style lang="less" scoped>
@import "../../styles/ucUserTask.less";
</style>





