<template>
    <div class="info-card-panner todo-panner">
        <header class="panner-hd">
            <el-button
                type="text"
                class="pull-right more"
                @click="go_to('/user/todos/list/all')"
            >
                更多
                <i class="jk-icon-right"></i>
            </el-button>
            <h2 class="tit">
                <span class="line">待办</span>事项
                <span class="todonum" v-if="count>0">({{count}})</span>
            </h2>
        </header>
        <div class="panner-bd">
            <ul class="art-wraper">
                <li class="art-item" v-for="(item,index) in data" :Key="index">
                    <el-link
                        :href="(user.user_type == 1?'/user/todos/detail/':'/user/todos/detail/')+item.id"
                        target="_blank"
                        :underline="false"
                        class="art-tit"
                    >{{item.task_name}}</el-link>
                    <p class="tips">
                        <span class="pull-left art-type">{{item.matters_info}}</span>
                        <time class="time pull-right">2018-01-22</time>
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "UcUserToDo",
    props: {
        data: {
            type: Array,
            // 对象或数组默认值必须从一个工厂函数获取
            default: function() {
                return [];
            }
        },
        user: {
            type: Object,
            // 对象或数组默认值必须从一个工厂函数获取
            default: function() {
                return {};
            }
        },
        count: {
            type: Number,
            // 对象或数组默认值必须从一个工厂函数获取
            default: 0
        }
    },
    data() {
        return {};
    },
    methods: {
        go_to(url) {
            this.$router.push(url);
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../styles/ucUserToDo.less";
</style>





