<template>
    <div class="home">
        <el-row class="user-msg-panel">
            <el-col
                :span="4"
                class="user-guide"
            >
                <span class="avatar avatarBox">
                    <el-upload
                        class="avatar-uploader"
                        :action="imgUploadUrl"
                        :headers="imgHeaders"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :on-success="handleAvatarSuccess"
                        :auto-upload="true"
                    >
                        <img
                            class="avator"
                            :src="util.reImg(userInfo.info.avatar)"
                        />
                    </el-upload>
                </span>

                <h4 class="name">用户ID：{{userInfo.info.geek_id}}</h4>

                <p
                    class="date"
                    v-text="timeInfo"
                ></p>
            </el-col>

            <el-col
                :span="20"
                class="user-msg-cont"
            >
                <div style="height:85px">
                    <div style="width:70%;float:left;">
                        <h3 class="name">{{userInfo.info.name}}（{{userInfo.info.nick}}）</h3>

                        <p class="invite">邀请码：{{userInfo.info.invitation_code?userInfo.info.invitation_code:'认证后才会生成邀请码'}}</p>

                        <p class="tips">
                            <span v-text="userInfo.info.area_value"></span>
                            <span v-if="userInfo.info.user_type==1">入驻类型：</span>
                            <span v-if="userInfo.info.user_type==2">认证类型：</span>

                            <el-button
                                v-if="userInfo.info.attestation ==1"
                                type="primary"
                                size="mini"
                                style="padding:0px;margin:0px;font-size:12px;padding:2px 4px;"
                                class="btn valid"
                                v-text="userInfo.info.attestation_value"
                            ></el-button>

                            <el-button
                                v-else-if="userInfo.info.attestation ==2 && userInfo.info.user_type==1"
                                type="primary"
                                size="mini"
                                style="padding:0px;margin:0px;font-size:12px;padding:2px 4px;"
                                class="btn valid"
                            >{{userInfo.info.attestation_type_value}}工程极客</el-button>

                            <el-button
                                v-else-if="userInfo.info.attestation ==2 && userInfo.info.user_type==2"
                                type="primary"
                                size="mini"
                                style="padding:0px;margin:0px;font-size:12px;padding:2px 4px;"
                                class="btn valid"
                            >{{userInfo.info.attestation_type_value}}雇主</el-button>

                            <el-button
                                v-else
                                type="primary"
                                size="mini"
                                style="padding:0px;margin:0px;font-size:12px;padding:2px 4px;"
                                class="btn valid"
                                @click="go_to1"
                            >{{userInfo.info.attestation_value}}</el-button>

                            <span v-if="userInfo.info.user_type==1">接受项目： {{userInfo.info.task_total}} 次</span>
                            <span v-else>发布项目： {{userInfo.info.task_total}} 次</span>
                            <span v-if="userInfo.info.user_type==1">合计获得酬金：{{userInfo.info.reward?userInfo.info.reward:'0.00'}} 元</span>
                            <span v-else>合计支付：{{userInfo.info.expenditure_total?userInfo.info.expenditure_total:'0.00'}}</span>
                        </p>
                    </div>
                    <div style="width:30%;float:right;height:85px;margin-top:8px;">
                        <img
                            :src="$img.ruzhu"
                            @click="goAttest"
                            v-if="userInfo.info.attestation_value == '未入驻'"
                        />
                    </div>
                </div>

                <el-row class="wallet">
                    <el-col :span="6">
                        <span>￥</span>
                        <span>{{userInfo.info.total | numberFormat(2)}}</span>
                        <p>总资金</p>
                    </el-col>

                    <el-col :span="6">
                        <span>
                            ￥
                            <span>{{userInfo.info.surplus | numberFormat(2)}}</span>
                        </span>
                        <p>可提现余额</p>
                    </el-col>

                    <el-col
                        :span="6"
                        v-if="userInfo.info.user_type==1"
                    >
                        <span>
                            ￥
                            <span>{{userInfo.info.bond | numberFormat(2)}}</span>
                        </span>
                        <p>已托管保证金</p>
                    </el-col>

                    <el-col
                        :span="6"
                        v-else
                    >
                        <span>
                            ￥
                            <span>{{userInfo.info.remuneration | numberFormat(2)}}</span>
                        </span>
                        <p>已托管酬金</p>
                    </el-col>

                    <el-col :span="6">
                        <span>
                            <span>{{userInfo.info.points_surplus}}</span>
                        </span>
                        <p>点券余额</p>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <div class="idx-cont-panel">
            <div class="cont-left">
                <div class="list-panel">
                    <div class="panel-hd">
                        <a
                            href="javascript:;"
                            class="more"
                            @click="allTask"
                        >查看所有项目</a>
                        <h4 class="title">我的项目清单</h4>
                    </div>
                    <div
                        v-if="checkAttestation == 1"
                        style="text-align: center;"
                    >
                        <img
                            :src="$img.attestation_geek_wait"
                            @click="goRouterPath('/user/attest')"
                            style="max-width: 300px;margin-bottom: 50px;margin-top: 25px;"
                        />
                    </div>
                    <div
                        v-if="checkAttestation == 3"
                        style="text-align: center;"
                    >
                        <img
                            :src="$img.attestation_geek_do"
                            style="max-width: 300px;margin-bottom: 50px;margin-top: 25px;"
                        />
                    </div>
                    <div
                        v-if="checkAttestation == 2"
                        style="text-align: center;"
                    >
                        <img
                            :src="$img.attestation_employer_wait"
                            @click="goRouterPath('/user/attest')"
                            style="max-width: 300px;margin-bottom: 50px;margin-top: 25px;"
                        />
                    </div>
                    <div
                        v-if="checkAttestation == 4"
                        style="text-align: center;"
                    >
                        <img
                            :src="$img.attestation_employer_do"
                            style="max-width: 300px;margin-bottom: 50px;margin-top: 25px;"
                        />
                    </div>
                    <div
                        v-if="checkAttestation == 0 && userInfo.info.user_type == 1 && count == 0"
                        style="text-align: center;"
                    >
                        <img
                            :src="$img.attestation_geek_finish"
                            style="max-width: 300px;margin-bottom: 50px;margin-top: 25px;"
                        />
                    </div>
                    <div
                        v-if="checkAttestation == 0 && userInfo.info.user_type == 2 && count == 0"
                        style="text-align: center;"
                    >
                        <img
                            :src="$img.attestation_employer_finish"
                            @click="goRouterPath('/task/publish')"
                            style="max-width: 300px;margin-bottom: 50px;margin-top: 25px;"
                        />
                    </div>
                    <div
                        class="panel-bd my_task_list"
                        v-if="tableData.length > 0 && checkAttestation == 0 && count > 0"
                    >
                        <el-table
                            class="task-table"
                            :data="tableData"
                        >
                            <el-table-column
                                prop="task_name"
                                label="项目名称"
                                width="250"
                            ></el-table-column>

                            <el-table-column
                                prop="task_type_value"
                                label="项目类型"
                            ></el-table-column>

                            <el-table-column
                                prop="status_value"
                                label="状态"
                            ></el-table-column>

                            <el-table-column
                                prop="operate"
                                label="操作"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="primary"
                                        style="padding:4px 6px;font-size:12px;"
                                        @click="dealTask(scope.row)"
                                    >处理</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <a
                            href="javascript:void(0);"
                            class="load-more"
                            @click="moreTask"
                            v-if="nowGetData.length > 5"
                        >
                            加载更多
                            <i class="jk-icon-xiajiantou"></i>
                        </a>
                    </div>
                    <div
                        v-if="tableData.length == 0 && checkAttestation == 0 && count > 0"
                        class="empty-data"
                    >
                        <span style="font-size:14px;color:#999;">暂无数据</span>
                    </div>
                </div>
            </div>

            <div class="cont-right">
                <div class="side-banner">
                    <img
                        style="cursor:pointer"
                        :src="$img.uc_idx_banner"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import UcUserInfo from "@/components/uc-user-info/UcUserInfo.vue";
import UcUserToDo from "@/components/uc-user-todo/ToDo.vue";
import UcUserWallet from "@/components/uc-user-wallet/Wallet.vue";
import UcUserTask from "@/components/uc-user-task/Task.vue";
export default {
    name: "ucIndex",
    metaInfo() {
        return {
            title: "控制台首页 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        UcUserInfo, // 用户信息
        UcUserToDo, // 待办事项
        UcUserWallet, // 钱包
        UcUserTask // 管理我的项目
    },
    computed: {
        ...mapState(["userInfo"])
    },
    data() {
        return {
            imgUploadUrl: "", //图片上传地址
            timeInfo: "", //时间信息
            imgHeaders: {
                //图片上传头部信息
                token: "",
                timestamp: "",
                secret: ""
            },
            page: {
                limit: 5,
                currentPage: 1
            },
            tableData: [],
            matterData: [],
            checkAttestation: 5,// 0 认证成功 1 极客待认证   2 雇主待认证  3 极客认证中 4 雇主认证中
            count: 0,
            nowGetData: []
        };
    },
    mounted() {
        this.getTimeInfo(); //根据时间做提示语
    },
    created() {
        //ajax请求相关数据放这里
        this.dealingTaskList(); //获取项目清单数据
        // this.dealingMatterList(); //获取待办事项数据
        this.getUploadHeader(); //加载图片上传头部信息
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]),
        //获取项目清单数据
        dealingTaskList() {
            let _this = this;
            if (_this.userInfo.info.attestation == 0) { // 未认证
                if (_this.userInfo.info.user_type == 1) {
                    _this.checkAttestation = 1;
                } else {
                    _this.checkAttestation = 2;
                }
            }
            if (_this.userInfo.info.attestation == 2) {
                _this.checkAttestation = 0;
            }
            if (_this.userInfo.info.attestation == 1 || _this.userInfo.info.attestation == 3) {
                if (_this.userInfo.info.user_type == 1) {
                    _this.checkAttestation = 3;
                } else {
                    _this.checkAttestation = 4;
                }
            }
            _this.post(
                "/task/task/dealing_task_list",
                {
                    page: _this.page.currentPage,
                    limit: _this.page.limit
                },
                data => {
                    if (data.code == 200) {
                        _this.nowGetData = data.data.list;
                        data.data.list.forEach(element => {
                            _this.tableData.push(element);
                        });
                        _this.count = data.data.count;
                    }
                }
            );
        },
        goRouterPath(path) {
            this.$router.push(path);
        },
        //获取待办事项数据
        // dealingMatterList() {
        //     let _this = this;
        //     _this.post(
        //         "/task/task/dealing_matter_list",
        //         {
        //             limit: 5
        //         },
        //         data => {
        //             if (data.code == 200) {
        //                 _this.matterData = data.data;
        //             }
        //         }
        //     );
        // },

        //项目清单 - 马上处理
        dealTask(task) {
            // if (task.status == 3) {
            this.$router.push("/user/task/todo?tk=" + task.tk);
            // } else {
            //     this.$message.success("请耐心等待审核");
            // }
        },

        //待办事项 - 查看
        dealMatter(val) {
            this.$router.push("/user/todos/detail/" + val);
        },
        //待办事项 - 查看
        goAttest() {
            this.$router.push("/user/attest");
        },

        //上传之前
        beforeUpload() {
            this.imgHeaders.timestamp = new Date().getTime();
        },

        //头像上传头部信息
        getUploadHeader() {
            this.imgUploadUrl =
                this.websiteConfigs.baseUrl +
                this.websiteConfigs.uploadUrl +
                "?group=user"; //获取上传地址
            this.imgHeaders.token = sessionStorage.getItem("token");
            this.imgHeaders.timestamp = new Date().getTime();
            this.imgHeaders.secret = this.websiteConfigs.secret;
        },

        //上传头像
        handleAvatarSuccess(res, file, callback) {
            let _this = this;
            _this.post(
                "/user/user/update_avatar",
                { avatar: res.data.file },
                function (r) {
                    if (r.code == 200) {
                        _this.ajaxUserInfo(); //刷新用户数据
                        return false;
                    }
                    _this.$message.error("头像更新失败");
                }
            );
        },

        //根据时间判断
        getTimeInfo() {
            let now = new Date();
            let hour = now.getHours();
            if (hour < 6) {
                this.timeInfo = "凌晨好！";
            } else if (hour < 9) {
                this.timeInfo = "早上好！";
            } else if (hour < 12) {
                this.timeInfo = "上午好！";
            } else if (hour < 14) {
                this.timeInfo = "中午好！";
            } else if (hour < 17) {
                this.timeInfo = "下午好！";
            } else if (hour < 19) {
                this.timeInfo = "傍晚好！";
            } else if (hour < 22) {
                this.timeInfo = "晚上好！";
            } else {
                this.timeInfo = "夜里好！";
            }
        },

        //所有项目
        allTask() {
            this.$router.push("/user/task/list?status=0");
        },

        //查看更多项目清单
        moreTask() {
            this.page.currentPage++;
            // this.page.limit = this.page.limit * 2;
            this.dealingTaskList();
        },

        //跳转到发布项目页面
        addtask() {
            //用户已登录
            if (this.userInfo.isLogin == true) {
                //雇主
                if (this.userInfo.info.user_type == 2) {
                    //是否需要认证才能发布项目（1.需要认证；0.不需要认证）
                    if (this.userInfo.config.is_atte == 1) {
                        if (
                            this.userInfo.attestation != "" &&
                            this.userInfo.info.attestation == 2
                        ) {
                            //已经认证
                        } else {
                            this.$message.error(
                                "请先完成雇主认证，才能发布项目"
                            );
                        }
                    } else {
                        this.$router.push("/task/publish"); //跳转到发布项目
                    }
                } else {
                    this.$message.error("只有雇主才能发布项目，请注册成为雇主");
                }
            } else {
                this.$message.error("请先登录后再操作");
                this.$router.push("/login/index?type=login");
            }
        },

        go_to1() {
            // console.log(123);
            //是否入驻（0.待入驻；1.已入驻；2.审核未通过；3.未入驻）
            if (this.userInfo.info.attestation == 2) {
                //跳转到编辑认证信息页面
                this.$router.push(
                    "/user/attest/edit?id=" +
                    this.userInfo.info.attestation_type
                );
            } else if (
                this.userInfo.info.attestation == 3 ||
                this.userInfo.info.attestation == 0
            ) {
                //未入驻,跳转到入驻页面
                this.$router.push("/user/attest");
            } else {
                //都不是就返回用户首页
                this.$router.push("/user/index");
            }
        }
    }
};
</script>

<style lang="less" scoped>
.user-msg-panel {
    height: 180px;
    background: white;
}
.user-guide {
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    height: 180px;
    background: url("~@/assets/img/uc_idx_line.jpg") top right no-repeat;
    .avatar {
        display: block;
        margin: 15px auto;
        border-radius: 50%;
        border: 1px solid #e3e3e3;
        width: 62px;
        height: 62px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .name {
        margin: 0;
        color: #333;
        font-weight: normal;
    }
    .date {
        color: #999999;
    }
}
.user-msg-cont {
    padding: 10px 20px;
    height: 180px;
    .name {
        margin: 5px 0;
        font-size: 16px;
        font-weight: normal;
        color: #333;
    }
    .invite {
        font-size: 12px;
        color: #333;
        margin: 10px 0;
    }
    .tips {
        font-size: 12px;
        color: #999999;
        line-height: 16px;
        span {
            padding: 0 10px;
            & + span {
                border-left: 1px solid #999999;
            }
            &:first-child {
                padding-left: 0;
            }
        }
    }
    .wallet {
        margin-top: 20px;
        width: 100%;
        text-align: center;
        span {
            font-size: 20px;
            font-weight: normal;
            color: #666;
        }
        p {
            font-size: 12px;
            color: #999;
        }
        .el-col {
            & + .el-col {
                border-left: 1px solid #eaeaea;
            }
        }
    }
}
.idx-cont-panel {
    margin-top: 10px;
    overflow: hidden;
    .cont-left {
        width: 713px;
        float: left;
        background: white;
    }
    .cont-right {
        width: 248px;
        float: right;
        background: white;
    }
}
.list-panel {
    width: 100%;
    overflow: hidden;
    .panel-hd {
        padding: 20px;
        line-height: 20px;
    }
    .more {
        float: right;
        font-size: 14px;
        color: #999;
    }
    .title {
        margin: 0;
        font-size: 14px;
        color: #333;
        font-weight: normal;
    }
}
/deep/.task-table {
    width: 100%;
    th {
        background: #f7f7f7 !important;
        height: 40px;
        padding: 0;
        line-height: 40px;
        font-size: 12px;
        color: #999999;
        &.el-table_1_column_1 {
            padding-left: 20px;
        }
    }
    td {
        background: white !important;
        height: 40px;
        padding: 0;
        line-height: 40px;
        font-size: 12px;
        color: #333;
        &.el-table_1_column_1 {
            padding-left: 20px;
        }
    }
}
.load-more {
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    color: #999999;
}
.matter-info {
    padding: 0 20px;
    a {
        display: block;
        color: #a0a0a0;
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
            color: #f26666;
        }
    }
}
.publish-btn {
    display: block;
    width: 200px;
    height: 40px;
    border-radius: 4px;
    background: #587ddc;
    font-size: 14px;
    color: #fff;
    padding: 0;
    border: none;
    line-height: 40px;
    margin: 20px auto;
}
.side-banner {
    margin: 0px;
    padding: 0px;
    width: 100%;
    padding: 10px;
    img {
        width: 100%;
        height: 100%;
    }
}
.avatarBox {
    width: 80px;
    height: 80px;
    overflow: hidden;
}
.empty-data {
    text-align: center;
    height: 300px;
    line-height: 300px;
    color: #999;
    font-size: 14px;
}
.empty-data-2 {
    text-align: center;
    height: 40px;
    line-height: 40px;
}
.my_task_list {
    /deep/.el-table .cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>